'use strict'

const _ = require('lodash')
const santaTypesDefinitions = require('../../../definitions/santaTypesDefinitions')
const inlineContentMixin = require('../../../mixins/inlineContentMixin')
const scrollMixin = require('../../../mixins/scrollMixin/scrollMixin')
const skinsJson = require('../skins/skins.json')
const skinBasedComp = require('../../../mixins/skinBasedComp')
const createReactClass = require('create-react-class')
const PropTypes = require('prop-types')

module.exports = ({defaultSkinName, compType, displayName = 'WixContainer', includeScrollMixin = false, legacyInlineContent = false, pointerEventsNone = false}) => {
    // Create ordered skins where default skin is first
    const skins = _.pick(skinsJson, [defaultSkinName, ..._.keys(skinsJson)])

    return createReactClass({
        displayName,

        mixins: [
            ...includeScrollMixin ? [scrollMixin] : [], 
            skinBasedComp(skins), 
            ...legacyInlineContent ? [] : [inlineContentMixin]
        ],

        propTypes: {
            isLoading: PropTypes.bool,
            isMeshLayoutMechanism: santaTypesDefinitions.Layout.isMeshLayoutMechanism,
            layoutContainerClassName: santaTypesDefinitions.Layout.layoutContainerClassName
        },

        statics: {
            getComponentSkins: () => skins,
            compType
        },
    
        getSkinProperties() {
            const {isMeshLayoutMechanism, compProp} = this.props
            const isLoading = _.get(compProp, 'isLoading')
            const loaderStyle = _.isUndefined(isLoading) ? {} : {opacity: isLoading ? 1 : 0}
            const inlineContentStyle = _.isUndefined(isLoading) ? {} : {opacity: isLoading ? 0 : 1}
            return {
                '': {
                    style: isMeshLayoutMechanism && pointerEventsNone ? {
                        pointerEvents: 'none'
                    } : {}
                },
                loader: {
                    style: isMeshLayoutMechanism && pointerEventsNone ? _.assign(loaderStyle, {
                        pointerEvents: 'none'
                    }) : loaderStyle
                },
                inlineContent: {
                    className: this.props.layoutContainerClassName,
                    style: isMeshLayoutMechanism && pointerEventsNone ? _.assign(inlineContentStyle, {
                        pointerEvents: 'none'
                    }) : inlineContentStyle,
                    children: legacyInlineContent ? this.props.children : this.getChildrenRenderer()
                }
            }
        }
    })
}
